import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import './Ufiles.css'
import { Breadcrumb, Button, Container, Form, Modal, Overlay, Tooltip } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import UFiles from './UFiles';
import { dateDisplay } from '../helpers/functions';

function SchooF() {
    const [topics, seTopics] = useState([]);
    const [show, setShow] = useState(false);
    const [uploads, setUploads] = useState([]);
    const [currURL, setCurrURL] = useState("");
    const [modal, setModal] = useState(false);
    const target = useRef(null);
    const option = useRef(null);

    useEffect(() => {
        axios.post("api/schoolfiles")
            .then((res) => {
                seTopics(res.data.topics);
                setUploads(res.data.uploaded);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const uploadFile = (e) => {
        const acceptedTypes = ["image/*", ".pdf", "application/pdf"];
        const selectedFileType = e.target.files[0].type;
        if (acceptedTypes.includes(selectedFileType) || selectedFileType.startsWith("image/")) {
            console.log("fileupload attempt");
            const data = new FormData();
            data.append("file", e.target.files[0]);
            let ext = e.target.files[0].name.split('.').pop();
            axios.post("api/uploadschoolf", data, { params: { option: option.current.value, ext } })
                .then((res) => {
                    console.log(res.data);
                    setUploads([...uploads,{topic:topics.find(topic => topic.idtopics === parseInt(option.current.value)).topic,url:res.data.url}]);
                    
                }).catch((error) => {
                    console.error('Error:', error);
                });
        } else {
            setShow(true);
            setTimeout(() => {
                setShow(false);
            }, 3000);
        }
    }

    return (
        <>
            <Breadcrumb>
                <Link to="/principal">{'<-<-'} חזרה</Link>
            </Breadcrumb>
            <Container className='text-center'>
                <Row>
                    <Col xs={0} md={3} lg={3} xl={3} xxl={3}></Col>
                    <Col xs={6} md={4} lg={4} xl={4} xxl={4}>
                        <Form.Select ref={option} aria-label="בחר נושא">
                            {topics.map((topic) => {
                                return <option value={topic.idtopics} key={topic.idtopics}>{topic.topic}</option>
                            })}
                        </Form.Select>
                    </Col>
                    <Col xs={6} md={2} lg={2} xl={2} xxl={2}>
                        <Form.Control type="file" className="d-none" id="file" name="file" accept="image/*,.pdf" onChange={uploadFile} />
                        <Form.Label ref={target} for="file" className='btn btn-outline-primary ms-2'>
                            להעלאת קובץ
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-paperclip" viewBox="0 0 16 16">
                                <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                            </svg>
                        </Form.Label>
                        <Overlay target={target.current} show={show} placement="bottom">
                            <Tooltip id="overlay">
                                לא ניתן להעלות קובץ מהסוג הנבחר
                            </Tooltip>
                        </Overlay>
                    </Col>
                </Row>
                <hr />
                {uploads.length > 0 && <h2>קבצים שהועלו</h2>}
                {uploads.map((upload) => {
                    return <Row key={upload.url} className='mb-1'>
                        <Col xs={6} md={1} lg={1} xl={1} xxl={1}>{upload.topic}</Col>
                        <Col xs={3} md={2} lg={2} xl={2} xxl={2}>{dateDisplay(upload.date)}</Col>
                        <Col xs={3} md={1} lg={1} xl={1} xxl={1}>
                            <Button variant="primary" className='ms-0 p-1' onClick={() => { setCurrURL(upload.url); setModal(true) }} title='הצג קובץ'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                    <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                    <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                </svg>
                            </Button>
                        </Col>
                    </Row>
                })}

                <Modal show={modal} onHide={() => setModal(false)}>
                    <Modal.Body><UFiles key={currURL} url={currURL} /></Modal.Body>
                </Modal>
            </Container>
        </>
    );
}
export default SchooF;