export const getFormattedDate = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  // Format options: YYYY-MM-DD_HH-mm-ss
  return `${year}-${month}-${day}_${hours}-${minutes}-${seconds}`;
};

export const dateDisplay = (timestamp) => {
  const date = new Date(timestamp);

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    timeZone: "Asia/Jerusalem"
  };

  return date.toLocaleString("en-IL", options);
}

export const dateDisplayNoTime = (timestamp) => {
  const date = new Date(timestamp);

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    timeZone: "Asia/Jerusalem"
  };

  return date.toLocaleString("en-IL", options);
}

export const createOption = (arr) => {
  return arr.map((item) => (
    <option
      key={item.id}
      value={item.id}
    >
      {item.rep}
    </option>
  ));
};