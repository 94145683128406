import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import './Ufiles.css'
function UFiles({ url }) {
    return (
        <>
            <Card>
                <Row className="m-0 p-0">
                    <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="border border-dark p-0">
                        <iframe src={url} title="My file" width={'100%'} height={"100%"} />
                    </Col>
                </Row>
            </Card>
        
        </>
    );
}
export default UFiles;
