import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Modal,
  Form,
} from "react-bootstrap";

const TransportationApprovals = () => {
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState(null);
  const [rejectionReason, setRejectionReason] = useState("");

  const handleReject = () => {
    // Perform rejection action here
    setShowRejectModal(false);
  };

  return (
    <Container>
      <h2 className="mt-4">Transportation Department Approvals</h2>
      {/* Display various types of requests */}
      {/* Example: Approval for budget allocation, adding suppliers, payment requests, etc. */}
      {/* Each type of request will have its own section */}

      {/* Sample Section: Budget Allocation */}
      <Card className="mt-4">
        <Card.Header>Budget Allocation</Card.Header>
        <Card.Body>
          {/* Display summary of budget allocation requests */}
          {/* Each request should have option to approve or reject */}
          {/* Show detailed information upon request selection */}
          <Button
            variant="primary"
            onClick={() => setSelectedRequest(/* selected request */)}
          >
            View Details
          </Button>
        </Card.Body>
      </Card>

      {/* Detailed View Modal */}
      <Modal
        show={selectedRequest !== null}
        onHide={() => setSelectedRequest(null)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Detailed Request Information</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Display detailed information of selected request */}
          {/* Allow user to approve or reject the request */}
          {/* If rejected, show modal for entering rejection reason */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setSelectedRequest(null)}>
            Close
          </Button>
          <Button variant="danger" onClick={() => setShowRejectModal(true)}>
            Reject
          </Button>
          <Button variant="success">Approve</Button>
        </Modal.Footer>
      </Modal>

      {/* Rejection Reason Modal */}
      <Modal show={showRejectModal} onHide={() => setShowRejectModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Enter Rejection Reason</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="rejectionReason">
            <Form.Control
              as="textarea"
              rows={3}
              value={rejectionReason}
              onChange={(e) => setRejectionReason(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowRejectModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleReject}>
            Reject
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default TransportationApprovals;
