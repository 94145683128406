import React, { useState, useEffect } from 'react';
import axios from "axios";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useSearchParams, useNavigate } from "react-router-dom"
import UFiles from "./UFiles"
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import Alert from 'react-bootstrap/Alert';
import './AddFiles.css'
import { Spinner } from 'react-bootstrap';

const NOTENAME = ["nsigned", "ntz", "npolice", "nhealth", "nwork", "npen2", "npen4", "npen5", "nform", "nbank", "nadvise", "ndoc", "npermit", "nfamily", "nalready", "nextra"]
const HORAAH = ["usigned", "utz", "uhealth", "uwork", "upen2", "upen4", "upen5", "uform", "ubank", "uadvise", "udoc", "ufamily", "ualready", "uextra"]
const EZER = ["usigned", "utz", "upolice", "uhealth", "upen2", "upen5", "uform", "ubank", "upermit", "ufamily", "uextra"]
let HEBREW = {
    usigned: 'סט מסמכים מלא וחתום ע"י העובד, מנהל ביה"ס והמפקח  מטעם "בני יוסף".<span class="text-danger">*</span>',
    utz: "צילום ת.ז. + ספח.<span class='text-danger'>*</span>",
    upolice: "<a href='https://drive.google.com/uc?export=download&id=1H_7ViJC5VCtFX9E2EPJSEQVdUFW9hDGl' target='_blank'>אישור ממשטרת ישראל</a> על העדר מניעה להעסקה במוסדות חינוך.<span class='text-danger'>*</span>",
    uhealth: "אישור מרופא משפחה על מצב בריאות תקין.<span class='text-danger'>*</span>",
    uwork: "אישור נתוני העסקה ממשרד החינוך. (מערכת הפורטל) <span class='text-danger'>*</span>",
    upen2: "<a href='https://drive.google.com/uc?export=download&id=1akmSxuhadM3d2ZiWAY2YkTLzErCbmO-_' target='_blank'>נספח 2 - קרן פנסיה.</a> ו/או <a href='https://drive.google.com/uc?export=download&id=1GpuOF-mn6VsBQaPMpwuTPRfNOrRMrft9' target='_blank'>נספח 3 - ביטוח מנהלים.</a>(כולל כל המסמכים הנדרשים, בהתאם למפורט בנוהל הליך הצטרפות לפנסיה/ השתלמות/ גמל)<span class='text-danger'>*</span>",
    upen4: "<a href='https://drive.google.com/uc?export=download&id=1yD6yngFa0r3dhQ6Dw95rJNebUh1u2pJ-' target='_blank'>נספח 4 - קופת גמל.</a>(כולל כל המסמכים הנדרשים, בהתאם למפורט בנספח)<span class='text-danger'>*</span>",
    upen5: "<a href='https://drive.google.com/uc?export=download&id=1H2FTsnGpqqnUEnYrfsSI8LCZiqAwrZng' target='_blank'>נספח 5 - קרן השתלמות.</a>(כולל כל המסמכים הנדרשים, בהתאם למפורט בנספח)<span class='text-danger'>*</span>",
    uform: "<a href='https://drive.google.com/uc?export=download&id=1uUx0c0tL_Gj_Uiuj4sEQ2mHGfsJJBWDe' target='_blank'>טופס 101.</a> כולל כל מסמך אחר הרלוונטי לצורך חישוב השכר כגון: תאום מס, גמלת ילד נכה וכדו'<span class='text-danger'>*</span>",
    ubank: "צילום שיק או אישור פרטי ח-ן בנק עדכני.<span class='text-danger'>*</span>",
    uadvise: "במידה והעובד מיועד לתפקיד יועץ, יש להעלות אישור העסקה מהמפקח על היועצים.",
    udoc: "במידה והעובד מיועד לעבודה בתפקיד פרא רפואי יש להעלות אישורים כדלקמן:קלינאית תקשורת/ריפוי בעיסוק – רישיון משרד הבריאות מטפלת באומנות – אישור מחוון <a href='https://docs.google.com/forms/d/e/1FAIpQLSdcivlV575C9bgSn70C8TfnqSJwjctL6fUAnzk6DxEz3qP3SQ/viewform' target='_blank'> בפורטל משרד החינוך</a>",
    upermit: 'במידה והמועמד מועסק כבר כעובד הוראה ומיועד לעבודה כשרת/מזכיר יש לקבל אשור העסקה מהרב יזדי -סמנכ"ל פדגוגיה. וכן להפך.',
    ufamily: "וועדת חריגים-במידה והמועמד מצהיר על קרבת משפחה יש <a href='https://drive.google.com/uc?export=download&id=1g4EJcGJPSkmY3JFVu85k0XJxIzPDwVbF' target='_blank'>למלא טופס</a>  לוועדת חריגים",
    uextra: "כל מסמך נוסף שתרצו להוסיף.",
    ualready: 'במקרה והמועמד מועסק כבר כעובד עזר ומיועד לעבודה כעובד הוראה יש להעלות אישור העסקה מהרב יזדי – סמנכ"ל פדגוגי.'
}

const AppFiles = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const id = searchParams.get('id');
    const [fileData, setFileData] = useState("");
    const [newname, setNewName] = useState("");
    const [currURL, setCurrURL] = useState("");
    const [notuploaded, setNotUploaded] = useState(false);
    const [files, setFiles] = useState({});
    const [notes, setNotes] = useState({});
    const [show, setShow] = useState(false);
    const [show1, setShow1] = useState(false);
    const [ALL, setALL] = useState([]);
    const [status, setStatus] = useState('2');
    const [err, setErr] = useState({});
    const [showErr, setShowErr] = useState('');

    const handleClose = () => {
        setShow(false)
        setShow1(false)
    };
    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow1(true);

    useEffect(() => {
        handleShow1();
        let filenames
        let allFiles = {}
        let allNotes = {}
        let allErr = {}
        if (id > 0) {

            axios.post("api/form/upload", { id })
                .then((res) => {
                    if (res.data.msg === 'ok') {
                        filenames = res.data.form
                        for (const elem of NOTENAME) {
                            allNotes[elem] = filenames[elem];
                            let temp = `err${elem.slice(1)}`
                            allErr[temp] = filenames[temp];
                        }
                        setNotes(allNotes)
                        setErr(allErr)
                        let temp = filenames.formtype === 0 ? HORAAH : EZER
                        setALL(temp)
                        if (filenames.formtype === 0) {
                            HEBREW = { ...HEBREW, upen5: "<a href='https://drive.google.com/uc?export=download&id=1H2FTsnGpqqnUEnYrfsSI8LCZiqAwrZng' target='_blank'>נספח 5 - קרן השתלמות.</a>(כולל כל המסמכים הנדרשים, בהתאם למפורט בנספח) <a href='https://drive.google.com/uc?export=download&id=1yCex7AVwBTK3CLHTbU0z1ks_h_thmDn3'>טופס הצטרפות</a> לקרן השתלמות.<span class='text-danger'>*</span>" }
                        }
                        setStatus(filenames.status)
                        async function fetchFile(fileName, name) {
                            await axios.post(`api/uploads`, { fileName })
                                .then((res) => {
                                    const url = res.data.url
                                    console.log("back from server url", url);
                                    allFiles[name] = url;
                                });
                        }
                        async function processArray() {
                            for (const elem of temp) {
                                if (filenames[elem] === '') {
                                    allFiles[elem] = '';
                                } else {
                                    await fetchFile(filenames[elem], elem)
                                }
                            }
                            setFiles(allFiles)
                        }
                        processArray()
                    }
                }).catch((error) => {
                    console.error('Error:', error);
                });
        }
    }, []);
    useEffect(() => {
        if (newname !== '') {
            uploadFile()
        }
    }, [newname]);
    useEffect(() => {
        console.log(err)
    }, [err]);
    useEffect(() => {
        let permit = true;
        if (ALL.length === 0 || status === 2 || status === 4) {
            permit = false;
        }
        for (let i = 0; i < ALL.length; i++) {
            if (err["err" + ALL[i].slice(1)] === 2) {
                permit = false;
            }
            if (["uadvise", "udoc", "upermit", "ufamily", "uextra", "ualready"].includes(ALL[i])) {
                continue;
            }
            if (files[ALL[i]] === '') {
                permit = false;
            }
        }
        setNotUploaded(permit);
    }, [files]);

    const changeFile = (i) => {
        console.log(notes)
        console.log(err)
        console.log(i);
        if (files[i] !== '') {
            setCurrURL(files[i]);
            handleShow()
        }
    }

    const getFile = (e) => {
        setFileData(e.target.files[0]);
        setNewName(e.target.name)
    };

    const uploadFile = () => {
        console.log("fileupload attempt");
        const data = new FormData();
        data.append("file", fileData);
        let ext = fileData.name.split('.').pop();
        axios.post("api/upload", data, { params: { newname, id, ext } })
            .then((res) => {
                console.log(res.data);
                if (res.data.status) {
                    let newobj = { ...files }
                    console.log("newobj", newobj)
                    newobj[newname] = res.data.url
                    console.log("url name", res.data.url)
                    console.log("newobj", newobj)
                    setFiles(newobj)
                    setCurrURL(res.data.url)
                    let errors = { ...err }
                    errors["err" + newname.slice(1)] = 1
                    setErr(errors)
                    setNewName("")
                }
            }).catch((error) => {
                console.error('Error:', error);
                if (error.response.data.msg == null) {
                    setShowErr(" ארעה תקלה נסו שנית עוד דקה בידקו שהקובץ אינו גדול מ 2mb")
                } else {
                    setShowErr(error.response.data.msg)
                }
                setNewName("")
            });
    };

    const sendForm = () => {
        if (notuploaded) {
            axios.post("api/changestatus", { id, "status": "2" })
                .then((res) => {
                    navigate('/principal')
                }).catch((error) => {
                    console.error('Error:', error);
                });
        }
    }
    return (
        <>
            <Row className="m-0 p-0">
                <Col xs={12} md={12} lg={12} xl={12} xxl={12}>
                    <Card className="m-2">

                        <Card.Title className='text-center'>
                            {showErr !== '' &&
                                <Modal show={showErr !== ''} onHide={() => setShowErr('')} >

                                    <Alert variant="danger" className='m-0' onClick={() => setShowErr('')}>
                                        <h5 className='text-center p-5 m-0'>{showErr}</h5>
                                    </Alert>
                                </Modal>

                            }
                            <Button variant="warning" className="mt-1 me-1 ps-1 pe-1" onClick={sendForm} disabled={!notuploaded}>הגשת הבקשה</Button>
                            <Button variant="primary" className="mt-1 me-1 ps-1 pe-1" onClick={handleShow1} title='הערות להעלאת קבצים'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-info-square" viewBox="0 0 16 16">
                                    <path d="M14 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z" />
                                    <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                                </svg>
                            </Button>
                        </Card.Title>
                        <Card.Body>
                            {ALL.map((item, i) => (
                                <Form key={item}>
                                    <Row className='m-1 p-0'>
                                        <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                                            <Form.Label>{`${i + 1})`} <span dangerouslySetInnerHTML={{ __html: HEBREW[item] }} /></Form.Label><br />
                                            <Form.Label><b>{notes["n" + item.slice(1)] === '' ? '' : `הערת הרשת: ${notes["n" + item.slice(1)]}`}</b></Form.Label>
                                        </Col>
                                        <Col xs={11} md={5} lg={5} xl={5} xxl={5}>
                                            <Form.Group className="mb-2">
                                                {(notes["n" + item.slice(1)] === '' && status === 3) || status === 2 || status === 4 ? '' : <>
                                                    <Form.Control type="file" className="d-none" id={item} name={item} onChange={getFile} />
                                                    <Form.Label for={item} className={(status === 3 && err["err" + item.slice(1)] === 1) || (status === 1 && files[item] !== '') ? 'btn btn-warning ms-2' : 'btn btn-success ms-2'}>
                                                        {(status === 3 && err["err" + item.slice(1)] === 1) || (status === 1 && files[item] !== '') ? 'לחץ להחלפת הקובץ הקיים' : 'לחץ להעלאת קובץ'}

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-paperclip" viewBox="0 0 16 16">
                                                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                                                        </svg>
                                                    </Form.Label>
                                                </>}
                                                {newname === item && <Spinner animation="border" className='pt-1' variant="primary" />}
                                            </Form.Group>
                                        </Col>
                                        <Col xs={1} md={1} lg={1} xl={1} xxl={1}>
                                            <Button variant="primary" className='ms-0 p-1' onClick={() => changeFile(item)} disabled={files[item] === ''} title='הצג קובץ'>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                                    <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                                    <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                                </svg>
                                            </Button>
                                        </Col>
                                    </Row>
                                    <hr className='m-0 p-0' />
                                </Form>
                            ))}
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Card className="m-4 p-3">
                <Card.Title>הערות להעלאת קבצים:</Card.Title>
                <Card.Body>
                    1.	אפשר להעלות קבצים עד גודל קובץ מקסימלי של 2 MB.<br />
                    2.	אפשר להעלות קבצים מסוג: PDF, PNG, JPG, JPEG. (עדיפות ל- PDF)<br />
                    3.	ניתן לבצע המרת קבצים ל- PDF בקישור הבא <a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank" rel="nore noreferrer">באתר חיצוני</a>.<br />
                    4.	לכל שדה של העלאת קובץ אפשר להעלות קובץ אחד, לכן לאחר שהעלתם קובץ בשורה מסוימת, העלאת קובץ נוסף תדרוס את הקודמת.<br />
                    5.	ככל שברצונכם להעלות 2 קבצים בשורה אחת, יש לאחד בין הקבצים.<br />
                    6.	ניתן לבצע איחוד קבצים באתר חיצוני בקישור הבא <a href="https://www.ilovepdf.com/merge_pdf" target="_blank" rel="nore noreferrer">באתר חיצוני</a>.<br />
                    7.	ניתן לבצע כיווץ קבצי תמונות באתר חיצוני בקישור הבא <a href="https://www.iloveimg.com/compress-image" target="_blank" rel="nore noreferrer">באתר חיצוני</a>.<br />
                    8.	ניתן לבצע כיווץ קבצי PDF באתר חיצוני בקישור הבא <a href="https://www.ilovepdf.com/compress_pdf" target="_blank" rel="nore noreferrer">באתר חיצוני</a>.<br />
                </Card.Body>
            </Card>
            <Modal show={show} onHide={handleClose}>
                <Modal.Body><UFiles key={currURL} url={currURL} /></Modal.Body>
            </Modal>
            <Modal show={show1} onHide={handleClose} dir="rtl">
                <Modal.Header>
                    <Modal.Title>הערות להעלאת קבצים:</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    1.	אפשר להעלות קבצים עד גודל קובץ מקסימלי של 2 MB.<br />
                    2.	אפשר להעלות קבצים מסוג: PDF, PNG, JPG, JPEG. (עדיפות ל- PDF)<br />
                    3.	ניתן לבצע המרת קבצים ל- PDF בקישור הבא <a href="https://www.ilovepdf.com/jpg_to_pdf" target="_blank" rel="nore noreferrer">באתר חיצוני</a>.<br />
                    4.	לכל שדה של העלאת קובץ אפשר להעלות קובץ אחד, לכן לאחר שהעלתם קובץ בשורה מסוימת, העלאת קובץ נוסף תדרוס את הקודמת.<br />
                    5.	ככל שברצונכם להעלות 2 קבצים בשורה אחת, יש לאחד בין הקבצים.<br />
                    6.	ניתן לבצע איחוד קבצים באתר חיצוני בקישור הבא <a href="https://www.ilovepdf.com/merge_pdf" target="_blank" rel="nore noreferrer">באתר חיצוני</a>.<br />
                    7.	ניתן לבצע כיווץ קבצי תמונות באתר חיצוני בקישור הבא <a href="https://www.iloveimg.com/compress-image" target="_blank" rel="nore noreferrer">באתר חיצוני</a>.<br />
                    8.	ניתן לבצע כיווץ קבצי PDF באתר חיצוני בקישור הבא <a href="https://www.ilovepdf.com/compress_pdf" target="_blank" rel="nore noreferrer">באתר חיצוני</a>.<br />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        סגור
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AppFiles;