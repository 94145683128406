import { Container } from "react-bootstrap";


function Transport() {

    return (
        <>
            <Container className='text-center'>
                <h3 className='pt-2'>מערכת הסעות</h3>

            </Container>
        </>
    );
}
export default Transport;