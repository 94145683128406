import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import axios from "axios";

function Department() {
    const [rows, setRows] = useState([]);

    useEffect(() => {
         axios.post("api/getdepartmentusers")
             .then((res) => {
                 setRows(res.data.rows);
             }).catch((error) => {
                 console.error('Error:', error);
             });
    }, []);

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        
        axios.post("api/adddepartmentuser", Object.fromEntries(data.entries()))
            .then((res) => {
                setRows(res.data.rows);
            }).catch((error) => {
                console.error('Error:', error);
            });
    };
    return (
        <>
            <Container fluid className='text-center'>
                <h3 className='pt-2'>
                    מחלקות
                </h3>
                <hr />
                <Form onSubmit={handleSubmit}>
                    <Row className='text-end'>
                        <Col xs={12} md={6} lg={3} xl={3} xxl={3}>
                            <Form.Group className="mb-3" controlId="dep">
                                <Form.Select name="dep" required>
                                    <option value="1">ביטוחים</option>
                                    <option value="2">כספים</option>
                                    <option value="3">כח אדם</option>
                                </Form.Select>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={3} xl={3} xxl={3}>
                            <Form.Group className="mb-3" controlId="name">
                                <Form.Control name="name" type="text" placeholder="שם" required autoComplete="off" />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={6} lg={2} xl={3} xxl={3}>
                            <Form.Group className="mb-3" controlId="mail">
                                <Form.Control name="mail" type="email" placeholder="מייל" required autoComplete="off" />
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={4} lg={2} xl={2} xxl={2}>
                            <Form.Group className="mb-3" controlId="pass">
                                <Form.Control name="pass" type="password" placeholder="סיסמה" required autoComplete="off"/>
                            </Form.Group>
                        </Col>
                        <Col xs={12} md={2} lg={2} xl={1} xxl={1}>
                            <Button variant="primary" type="submit">הוסף</Button>
                        </Col>
                    </Row>
                </Form>

                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>מחלקה</th>
                            <th>שם</th>
                            <th>מייל</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rows.map((row, index) => {
                            return (
                                <tr key={row.id}>
                                    <td>{index + 1}</td>
                                    <td>{row.type === 1 ? "ביטוחים" : (row.type === 2 ? "כספים" : "כח אדם")}</td>
                                    <td>{row.rep}</td>
                                    <td>{row.mail}</td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </Table>

            </Container>
        </>
    );
}
export default Department;