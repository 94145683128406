import React, { useState, useEffect } from 'react';
import axios from 'axios';
import UFiles from './UFiles';
import { Button, Container, Form, Col, Row, Table, Breadcrumb, Badge } from 'react-bootstrap';
import TableToExcel from '../helpers/Excel';
import { getFormattedDate } from '../helpers/functions';

// based on the SEMEL it changes the display of the files. to view this page you must be type 3
// semel = 0 -> HORAAH or EZER (depends on the formtype)  semel = 1 -> INSURANCE semel = 2 -> PAYMENT semel = 3 -> MANPOWER

const HORAAH = ["usigned", "utz", "uhealth", "uwork", "upen2", "upen4", "upen5", "uform", "ubank", "uadvise", "udoc", "uextra"]
const EZER = ["usigned", "utz", "upolice", "uhealth", "upen2", "upen5", "uform", "ubank", "uextra"]
const INSURANCE = ["utz", "upen2", "upen4", "upen5"]
const PAYMENT = ["utz", "uform", "ubank", "uextra"]

const HEBREW = {
    usigned: 'סט מסמכים מלא וחתום ע"י העובד, מנהל ביה"ס והמפקח  מטעם "בני יוסף".',
    utz: "צילום ת.ז. + ספח.",
    upolice: "אישור ממשטרת ישראל על העדר מניעה להעסקה במוסדות חינוך.",
    uhealth: "אישור מרופא משפחה על מצב בריאות תקין.",
    uwork: "אישור נתוני העסקה ממשרד החינוך. (מערכת הפורטל) ",
    upen2: "נספח 2 - קרן פנסיה. ו/או נספח 3 - ביטוח מנהלים. (כולל כל המסמכים הנדרשים, בהתאם למפורט בנוהל הליך הצטרפות לפנסיה/ השתלמות/ גמל)",
    upen4: "נספח 4 - קופת גמל. (כולל כל המסמכים הנדרשים, בהתאם למפורט בנספח)",
    upen5: "נספח 5 - קרן השתלמות. (כולל כל המסמכים הנדרשים, בהתאם למפורט בנספח)",
    uform: "טופס 101. כולל כל מסמך אחר הרלוונטי לצורך חישוב השכר כגון: תאום מס, גמלת ילד נכה וכדו'",
    ubank: "צילום שיק או אישור פרטי ח-ן בנק עדכני.",
    uadvise: "במידה והעובד מיועד לתפקיד יועץ, יש להעלות אישור העסקה מהמפקח על היועצים.",
    udoc: 'במידה והעובד מיועד לתפקיד פרא רפואי, יש להעלות אישור מנהל אגף חנ"מ.',
    uextra: "כל מסמך נוסף שתרצו להוסיף.",
}

const Dep = () => {
    const [pagination, setPagination] = useState([]);
    const [rows, setRows] = useState([]);
    const [id, setId] = useState(0);
    const [index, setIndex] = useState(0);
    const [currURL, setCurrURL] = useState("");
    const [files, setFiles] = useState({});
    const [ALL, setALL] = useState([]);
    const [search, setSearch] = useState('');
    const SEMEL = sessionStorage.getItem('semel');

    useEffect(() => {
        axios.post("api/dep")
            .then((res) => {
                if (SEMEL === '1') {
                    setPagination(res.data.rows.filter(i => i.hideinsurance === 1))
                } else if (SEMEL === '2') {
                    setPagination(res.data.rows.filter(i => i.hidepay === 1))
                } else if (SEMEL === '3') {
                    setPagination(res.data.rows.filter(i => i.hidecalc === 1))
                } else {
                    setPagination(res.data.rows)
                }

                setRows(res.data.rows)
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    useEffect(() => {
        console.log(rows);
    }, [rows]);
    const showUploads = ((id, index) => {
        setId(id);
        setIndex(index);
        let allFiles = {};
        let temp;
        if (SEMEL === '0') {
            temp = pagination[index].formtype === 0 ? HORAAH : EZER
        } else if (SEMEL === '1') {
            temp = INSURANCE
        } else if (SEMEL === '2') {
            temp = PAYMENT
        }
        setALL(temp)
        async function fetchFile(fileName, name) {
            await axios.post(`api/uploads`, { fileName })
                .then((res) => {
                    const url = res.data.url
                    allFiles[name] = url;
                });
        }
        async function processArray() {
            for (const elem of temp) {
                if (pagination[index][elem] === '') {
                    allFiles[elem] = '';
                } else {
                    await fetchFile(pagination[index][elem], elem)
                }
            }
            console.log(allFiles);
            setFiles(allFiles)
            setCurrURL(allFiles.usigned);
        }
        processArray()
    })

    const changeFile = (i) => {
        if (files[i] !== '') {
            setCurrURL(files[i]);
        }
        if (files[i] === currURL && files[i] !== '') {
            openNew()
        }
    }
    const openNew = () => {
        window.open(currURL, '_blank');
    }

    const changecalc = (id) => {
        //שויך בחישובית אם זה 2 עדיין לא שויך אם זה 1
        axios.post("api/changecalc", { id })
            .then((res) => {
                console.log(res.data.msg);
            }).catch((error) => {
                console.error('Error:', error);
            });
        const targetIndex = rows.findIndex((row) => row.id === id);
        setRows([...rows, rows[targetIndex].calc = '2'])
    }

    const hideinsurance = (stat, id) => {
        axios.post("api/hideinsurance", { stat, id: id })
            .then((res) => {
                console.log(res.data.msg);
            }).catch((error) => {
                console.error('Error:', error);
            });
        const targetIndex = rows.findIndex((row) => row.id === id);
        const updatedRows = [...rows];
        updatedRows[targetIndex].hideinsurance = stat;
        setRows(updatedRows)
    }
    const hidepay = (stat, id) => {
        axios.post("api/hidepay", { stat, id: id })
            .then((res) => {
                console.log(res.data.msg);
            }).catch((error) => {
                console.error('Error:', error);
            });
        const targetIndex = rows.findIndex((row) => row.id === id);
        const updatedRows = [...rows];
        updatedRows[targetIndex].hidepay = stat;
        setRows(updatedRows)
    }
    const hidecalc = (stat, id) => {
        axios.post("api/hidecalc", { stat, id: id })
            .then((res) => {
                console.log(res.data.msg);
            }).catch((error) => {
                console.error('Error:', error);
            });
        const targetIndex = rows.findIndex((row) => row.id === id);
        const updatedRows = [...rows];
        updatedRows[targetIndex].hidecalc = stat;
        setRows(updatedRows)
    }

    // Function to extract specific columns from the data
    const extractSpecificColumns = (data) => {
        return data.map((row) => {
            const { id, fname, lname, tz, formtype, calc, uniqueworkerid, sname, semel, status4date, scity, father, sec, clean, sayat, class1, class2, class3, class4, class5, teacher, sub, teller, rep, year } = row;
            const statusTranslated = formtype === 0 ? "עובד הוראה" : "עובד עזר";
            const date = new Date(status4date)
            const display = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
            return {
                "#": id,
                "תאריך": display,
                "סמל": semel,
                "בית ספר": sname,
                "יישוב": scity,
                "מס' עובד": uniqueworkerid,
                "מועמד": fname + " " + lname,
                "תעודת זהות": tz,
                "סוג": statusTranslated,
                "תפקיד": [father > 0 ? "אב בית / שרת" : 0, sec > 0 ? "מזכיר/ה" : 0, clean > 0 ? "עובד/ת ניקיון" : 0, sayat > 0 ? "סייע/ת" : 0, class1 !== '' ? "מחנך/כת כיתה" : 0, class2 !== '' ? "מורה מקצועי/ת" : 0, class3 !== '' ? "פרא-רפואי/שילוב" : 0, class4 !== '' ? "ייעוץ" : 0, class5 !== '' ? "אחר" : 0].filter(Boolean).join(", "),
                "סטטוס העסקה": [teacher === 1 ? "משרת עובד/ת הוראה" : 0, sub === 1 ? "מילוי מקום לתקופה קצובה העולה על 51 יום" : 0].filter(Boolean).join(", "),
                "חשבת שכר": teller,
                "רפרנטית כח אדם": rep,
                "שוייך בחישובית": calc === 1 ? "לא" : "כן",
                "שנה": year
            };
        });
    };

    let specificColumnsData = extractSpecificColumns(pagination);

    return (
        <>
            <Container>
                {id === 0 ? (
                    <div>
                        <div className='d-flex pt-1 pb-1'>
                            <Form.Control className='w-25 ms-1' type="text" placeholder="חיפוש" value={search} onChange={(e) => { setSearch(e.target.value); setPagination(rows.filter(i => `${i.fname} ${i.lname}${i.tz}${i.formtype}${i.sname}${i.uniqueworkerid}${i.semel}${i.formtype === 0 ? 'עובד הוראה' : 'עובד עזר'}`.includes(e.target.value))) }} />
                            {
                                SEMEL === '1' && <>
                                    <Button className='me-2' variant="outline-primary" onClick={() => setPagination(rows.filter(i => i.hideinsurance === 1))}>הסתר</Button>
                                </>
                            }
                            {
                                SEMEL === '2' && <>
                                    <Button className='me-2' variant="outline-primary" onClick={() => setPagination(rows.filter(i => i.hidepay === 1))}>הסתר</Button>
                                </>
                            }
                            {
                                SEMEL === '3' && <>
                                    <Button className='me-2' variant="outline-primary" onClick={() => setPagination(rows.filter(i => i.hidecalc === 1))}>הסתר</Button>
                                </>
                            }
                            <Button className='me-2 ms-2' variant="outline-secondary" onClick={() => setPagination(rows)}>הצג הכל</Button>
                            <TableToExcel data={specificColumnsData} fileName={`data_${getFormattedDate()}`} />
                        </div>
                        <Table striped bordered>
                            <thead>
                                <tr>
                                    <th>תאריך</th>
                                    <th>סמל</th>
                                    <th>בית ספר</th>
                                    <th>יישוב מוסד</th>
                                    <th>מס' עובד</th>
                                    <th>מועמד</th>
                                    <th>תז</th>
                                    <th>סוג</th>
                                    <th>שנה</th>
                                    <th>תפקיד</th>
                                    <th>סטטוס העסקה</th>
                                    <th>חשבת שכר</th>
                                    <th>רפרנטית כח אדם</th>
                                    {SEMEL !== "3" ? <th>הצג</th> : <th>שיוך בחישובית</th>}
                                    <th>הסתר</th>
                                </tr>
                            </thead>
                            <tbody>
                                {pagination.map((i, index) => {
                                    let date = new Date(i.status4date)
                                    let display = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
                                    return (
                                        <tr key={i.id}>
                                            <td>{display}</td>
                                            <td>{i.semel}</td>
                                            <td>{i.sname}</td>
                                            <td>{i.scity}</td>
                                            <td>{i.uniqueworkerid}</td>
                                            <td>{i.fname + ' ' + i.lname}</td>
                                            <td>{i.tz}</td>
                                            <td>{i.formtype === 0 ? 'עובד הוראה' : 'עובד עזר'}</td>
                                            <td>{i.year}</td>
                                            <td>{
                                                [i.father > 0 ? "אב בית / שרת" : 0, i.sec > 0 ? "מזכיר/ה" : 0, i.clean > 0 ? "עובד/ת ניקיון" : 0, i.sayat > 0 ? "סייע/ת" : 0, i.class1 !== '' ? "מחנך/כת כיתה" : 0, i.class2 !== '' ? "מורה מקצועי/ת" : 0, i.class3 !== '' ? "פרא-רפואי/שילוב" : 0, i.class4 !== '' ? "ייעוץ" : 0, i.class5 !== '' ? "אחר" : 0].filter(Boolean).join(", ")
                                            }</td>
                                            <td>{
                                                [i.teacher === 1 ? "משרת עובד/ת הוראה" : 0, i.sub === 1 ? "מילוי מקום לתקופה קצובה העולה על 51 יום" : 0].filter(Boolean).join(", ")
                                            }</td>
                                            <td>{i.teller}</td>
                                            <td>{i.rep}</td>
                                            {SEMEL !== "3" ?
                                                <td className='text-center' onClick={() => showUploads(i.id, index)}><svg role="button" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
                                                    <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                                                </svg>
                                                </td> :
                                                <td className='text-center'>
                                                    {i.calc === 1 ? (
                                                        <Badge bg="primary" role='button' onClick={() => changecalc(i.id)}><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-plus-circle" viewBox="0 0 16 16">
                                                            <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                                                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                                                        </svg></Badge>
                                                    ) : (
                                                        <Badge bg="info"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                                            <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                                        </svg></Badge>
                                                    )}

                                                </td>
                                            }
                                            {SEMEL === "1" && <td className='text-center'>
                                                <Form.Check type="switch" checked={i.hideinsurance === 2} onChange={() => hideinsurance(i.hideinsurance === 2 ? 1 : 2, i.id)}></Form.Check>
                                            </td>}
                                            {SEMEL === "2" && <td className='text-center'>
                                                <Form.Check type="switch" checked={i.hidepay === 2} onChange={() => hidepay(i.hidepay === 2 ? 1 : 2, i.id)}></Form.Check>
                                            </td>}
                                            {SEMEL === "3" && <td className='text-center'>
                                                <Form.Check type="switch" checked={i.hidecalc === 2} onChange={() => hidecalc(i.hidecalc === 2 ? 1 : 2, i.id)}></Form.Check>
                                            </td>}
                                        </tr>
                                    )
                                })}

                            </tbody>
                        </Table>
                    </div>
                ) : (
                    <div className='mb-2'>
                        <Breadcrumb>
                            <Breadcrumb.Item href="#" onClick={() => setId(0)}>{'<-<-'} חזרה</Breadcrumb.Item>
                        </Breadcrumb>
                        <Table bordered className='mt-2'>
                            <thead>
                                <tr>
                                    <th>סמל</th>
                                    <th>בית ספר</th>
                                    <th>יישוב מוסד</th>
                                    <th>מס' עובד</th>
                                    <th>מועמד</th>
                                    <th>תז</th>
                                    <th>סוג</th>
                                    <th>שנה</th>
                                    <th>תפקיד</th>
                                    <th>סטטוס העסקה</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{pagination[index].semel}</td>
                                    <td>{pagination[index].sname}</td>
                                    <td>{pagination[index].scity}</td>
                                    <td>{pagination[index].uniqueworkerid}</td>
                                    <td>{pagination[index].lname + " " + pagination[index].fname}</td>
                                    <td>{pagination[index].tz}</td>
                                    <td>{pagination[index].formtype === 0 ? 'עובד הוראה' : 'עובד עזר'}</td>
                                    <td>{pagination[index].year}</td>
                                    <td>{
                                        [pagination[index].father > 0 ? "אב בית / שרת" : 0, pagination[index].sec > 0 ? "מזכיר/ה" : 0, pagination[index].clean > 0 ? "עובד/ת ניקיון" : 0, pagination[index].sayat > 0 ? "סייע/ת" : 0, pagination[index].class1 !== '' ? "מחנך/כת כיתה" : 0, pagination[index].class2 !== '' ? "מורה מקצועי/ת" : 0, pagination[index].class3 !== '' ? "פרא-רפואי/שילוב" : 0, pagination[index].class4 !== '' ? "ייעוץ" : 0, pagination[index].class5 !== '' ? "אחר" : 0].filter(Boolean).join(", ")
                                    }</td>
                                    <td>{
                                        [pagination[index].teacher === 1 ? "משרת עובד/ת הוראה" : 0, pagination[index].sub === 1 ? "מילוי מקום לתקופה קצובה העולה על 51 יום" : 0].filter(Boolean).join(", ")
                                    }</td>
                                </tr>
                            </tbody>
                        </Table>
                        <Row className='mb-2 mt-3'>
                            <Col xs={12} md={4} lg={4} xl={4} xxl={4} className='scroll'>
                                <Table striped bordered>
                                    <thead>
                                        <tr>
                                            <th>לחץ לפתיחת קובץ</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {ALL.map((item, i) => (
                                            <React.Fragment key={item}>
                                                <tr>
                                                    <td onClick={() => changeFile(item)}>
                                                        <p className="text-end">{`${i + 1}) ${HEBREW[item]} `}<i>{files[item] === '' ? 'לא הועלה קובץ' : ''}</i></p>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                            <Col xs={12} md={8} lg={8} xl={8} xxl={8}><UFiles key={currURL} url={currURL} /></Col>
                        </Row>
                        <div className='text-center'>
                            <Button variant="outline-secondary" onClick={() => setId(0)}>סגור</Button>
                        </div>
                    </div>
                )}

            </Container>
        </>
    )
}
export default Dep