import { Breadcrumb, Button, Container, Form, Modal, Overlay, Tooltip, Col, Row } from 'react-bootstrap';
import { useEffect, useRef, useState } from 'react';
import Uploads from './admin/Uploads';
import Status from './admin/Status';
import Users from './admin/Users';
import Options from './admin/Options';
import Mosdot from './admin/Mosdot';
import Edit from './admin/Edit';
import Department from './admin/Departments';
import Transport from './admin/Transport';

function Admin() {
    const [topics, seTopics] = useState(1);

    return (
        <>
            <Container fluid>
                <Row style={{ minHeight: "92vh" }}>
                    <Col xs={12} md={3} lg={2} xl={2} xxl={2} className='text-center bg-dark p-0'>
                        <h5 className='text text-light'>הרשת</h5>
                        <hr className='text text-light' />
                        <div className="ps-3"><Button variant="light" className="m-2 w-100" disabled={topics === 2} onClick={() => seTopics(2)}>קבצים שהועלו</Button></div>
                        <div className="ps-3"><Button variant="light" className="m-2 w-100" disabled={topics === 3} onClick={() => seTopics(3)}>נושאים לקבצים</Button></div>
                        <div className="ps-3"><Button variant="light" className="m-2 w-100" disabled={topics === 5} onClick={() => seTopics(5)}>מוסדות</Button></div>
                        <div className="ps-3"><Button variant="light" className="m-2 w-100" disabled={topics === 8} onClick={() => seTopics(8)}>הסעות</Button></div>
                        <br />
                        <h5 className='text text-light'>
                            מערכת מעל"ה
                        </h5>
                        <hr className='text text-light' />
                        <div className="ps-3"><Button variant="light" className="m-2 w-100" disabled={topics === 1} onClick={() => seTopics(1)}>סטטוס בקשות</Button></div>
                        <div className="ps-3"><Button variant="light" className="m-2 w-100" disabled={topics === 6} onClick={() => seTopics(6)}>מחלקות</Button></div>
                        <div className="ps-3"><Button variant="light" className="m-2 w-100" disabled={topics === 4} onClick={() => seTopics(4)}>מוסדות</Button></div>
                        <div className="ps-3"><Button variant="light" className="m-2 w-100" disabled={topics === 7} onClick={() => seTopics(7)}>ניהול משתמשים</Button></div>

                    </Col>
                    <Col xs={12} md={9} lg={10} xl={10} xxl={10} className='p-0'>
                        {topics === 3 && <Options />}
                        {topics === 2 && <Uploads />}
                        {topics === 1 && <Status />}
                        {topics === 4 && <Users />}
                        {topics === 5 && <Mosdot />}
                        {topics === 6 && <Department />}
                        {topics === 7 && <Edit />}
                        {topics === 8 && <Transport />}
                    </Col>
                </Row>

            </Container>
        </>
    );
}
export default Admin;