import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { Col, Container, Modal, Row, Table, Form } from 'react-bootstrap';
import UFiles from '../UFiles';
import { dateDisplay } from '../../helpers/functions';

function Uploads() {
    const [allfiles, setAllfiles] = useState([]);
    const [pagination, setPagination] = useState([]);
    const [modal, setModal] = useState(false);
    const [currURL, setCurrURL] = useState("");
    const search = useRef(null);

    useEffect(() => {
        axios.post("api/schooluploads")
            .then((res) => {
                setAllfiles(res.data.rows);
                setPagination(res.data.rows);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const getS3 = (url) => {
        axios.post("api/getuploads3", { url, bucket: "mschoolfiles" })
            .then((res) => {
                setCurrURL(res.data);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <>
            <Container className='text-center'>
                <h3 className='pt-2'>קבצים שהועלו על ידי בתי הספר</h3>
                <hr />
                <Row className='mb-1 mt-1 ps-3'>
                    <Col xs={0} md={10} lg={10} xl={10} xxl={10} className='p-0'></Col>
                    <Col xs={12} md={2} lg={2} xl={2} xxl={2} className='p-0'>
                        <Form.Control type="text" placeholder="חיפוש" ref={search} onChange={(e) => { setPagination(allfiles.filter(i => `${i.semel} ${i.date}${i.topic}`.includes(search.current.value))) }} />
                    </Col>
                </Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>תאריך העלאה</th>
                            <th>סמל</th>
                            <th>נושא</th>
                            <th>קישור לקובץ</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pagination.map((file) => {
                            return (
                                <tr key={file.id_f}>
                                    <td>{dateDisplay(file.date)}</td>
                                    <td>{file.semel}</td>
                                    <td>{file.topic}</td>
                                    <td role="button" onClick={() => { getS3(file.name); setModal(true); }}>
                                        לחץ לפתיחת הקובץ
                                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-paperclip" viewBox="0 0 16 16">
                                            <path d="M4.5 3a2.5 2.5 0 0 1 5 0v9a1.5 1.5 0 0 1-3 0V5a.5.5 0 0 1 1 0v7a.5.5 0 0 0 1 0V3a1.5 1.5 0 1 0-3 0v9a2.5 2.5 0 0 0 5 0V5a.5.5 0 0 1 1 0v7a3.5 3.5 0 1 1-7 0V3z" />
                                        </svg>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Container>
            <Modal show={modal} onHide={() => setModal(false)}>
                <Modal.Body><UFiles key={currURL} url={currURL} /></Modal.Body>
            </Modal>
        </>
    );
}
export default Uploads;