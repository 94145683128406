import axios from "axios";
import React, { useState, useEffect } from "react";
import { ProgressBar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Travel = () => {
  const navigate = useNavigate();
  const [percentage1, setPercentage1] = useState("");
  const [percentage2, setPercentage2] = useState("");
  const [totalMoney, setTotalMoney] = useState(0);
  const [lastmoney, setLastMoney] = useState(0);
  const [maxmoney, setMaxMoney] = useState(0);

  useEffect(() => {
    axios
      .post("api/traveldata")
      .then((res) => {
        console.log(res.data);
        setTotalMoney(res.data.row.advance_bugt);
        setLastMoney(res.data.row.prev_year_balance);
        setMaxMoney(res.data.row.max_hassot);
        if (res.data.row.hassaot_bdgt_mosad > 0) {
          navigate("/suppliers");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  const handlePercentageChange1 = (e) => {
    const value = e.target.value;
    setPercentage1(value);
    const remainingPercentage = 100 - parseInt(value);
    setPercentage2(remainingPercentage.toString());
  };

  const handlePercentageChange2 = (e) => {
    const value = e.target.value;
    setPercentage2(value);
    const remainingPercentage = 100 - parseInt(value);
    setPercentage1(remainingPercentage.toString());
  };

  const calculateSplitAmount = () => {
    const percentage1Value = parseInt(percentage1);
    const percentage2Value = parseInt(percentage2);

    const splitAmount1 = isNaN(percentage1Value)
      ? 0
      : ((totalMoney + lastmoney) * percentage1Value) / 100;
    const splitAmount2 = isNaN(percentage2Value)
      ? 0
      : ((totalMoney + lastmoney) * percentage2Value) / 100;

    return {
      splitAmount1,
      splitAmount2,
    };
  };

  const handleclick = () => {
    const data = {
      reqamount: ((totalMoney + lastmoney) * percentage2) / 100,
    };

    axios
      .post("api/travelsplit", data)
      .then((res) => {
        navigate("/suppliers");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  return (
    <div className="container h-100">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-md-6 mt-5 pt-5 d-flex flex-column align-items-center">
          <div className="d-flex">
            <p>תקציב שנתי: {totalMoney} </p>&nbsp;||&nbsp;
            <p>יתרה: {lastmoney} </p>&nbsp;||&nbsp;
            <b>
              <p>תקציב משוקלל: {totalMoney + lastmoney} </p>
            </b>
            &nbsp;||&nbsp;
            <p>מגבלה להסעות: {maxmoney} </p>
          </div>
          <div className="input-group mt-3">
            <input
              type="number"
              pattern="[0-9]{1,3}"
              className="form-control ms-1"
              placeholder="אחוז להורים"
              value={percentage1}
              onChange={handlePercentageChange1}
            />
          </div>
          <div className="input-group mt-3">
            <input
              type="number"
              pattern="[0-9]{1,3}"
              className="form-control ms-1"
              placeholder="אחוז להסעות"
              value={percentage2}
              onChange={handlePercentageChange2}
            />
          </div>
          <div className="container mt-3">
            <ProgressBar>
              <ProgressBar
                animated
                variant="success"
                now={percentage1}
                label={`${percentage1}%`}
                key={1}
              />
              <ProgressBar
                animated
                variant="warning"
                now={percentage2}
                label={`${percentage2}%`}
                key={2}
              />
            </ProgressBar>
          </div>
          {totalMoney > 0 && (
            <div className="d-flex mt-3">
              <p>להורים: {calculateSplitAmount().splitAmount1}</p>
              <p className="me-3">
                להסעות: {calculateSplitAmount().splitAmount2}
              </p>
            </div>
          )}
          {((totalMoney + lastmoney) * percentage2) / 100 > maxmoney && (
            <div className="alert alert-danger mt-3" role="alert">
              תקציב משוקלל גדול מהמגבלה להסעות
            </div>
          )}
          {percentage1 >= 0 &&
            percentage2 >= 0 &&
            (percentage1 !== "" || percentage2 !== "") &&
            ((totalMoney + lastmoney) * percentage2) / 100 < maxmoney && (
              <button
                className="btn btn-primary mt-3 align-self-center"
                onClick={handleclick}
              >
                שמור והמשך
              </button>
            )}
        </div>
      </div>
    </div>
  );
};

export default Travel;
