import React from "react";
import axios from "axios";
import { Form, Button, Container, Card, Col } from "react-bootstrap";
import { useNavigate, Link, useSearchParams } from "react-router-dom";

const EditContract = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");

  if (!id || isNaN(id) || id === "") {
    console.error("Invalid supplier ID:", id);
    navigate("/suppliers");
    return null; // Return early if the ID is invalid to prevent rendering the form
  }

  console.log("Supplier ID:", id);

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = {
      day_price: e.target.day_price.value,
      fromDate: e.target.fromDate.value,
      toDate: e.target.toDate.value,
      contract_id: id,
    };

    axios
      .post("/api/editcontract", formData)
      .then((res) => {
        console.log("Response data:", res.data);
        navigate("/suppliers");
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
      });
  };

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
    >
      <Col xs={12} md={6}>
        <Card className="m-3 p-4">
          <h1 className="text-center mb-2">עריכת ספק</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formday_price">
              <Form.Label>מחיר ליום</Form.Label>
              <Form.Control type="number" name="day_price" required />
            </Form.Group>
            <Form.Group controlId="formFromDate">
              <Form.Label>מתאריך</Form.Label>
              <Form.Control type="date" name="fromDate" required />
            </Form.Group>
            <Form.Group controlId="formToDate">
              <Form.Label>עד תאריך</Form.Label>
              <Form.Control type="date" name="toDate" required />
            </Form.Group>
            <div className="text-center mt-3">
              <Button variant="primary" type="submit">
                שלח
              </Button>
              <Link to="/suppliers" className="btn btn-secondary me-2">
                חזור
              </Link>
            </div>
          </Form>
        </Card>
      </Col>
    </Container>
  );
};

export default EditContract;
