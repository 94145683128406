import axios from "axios";
import React, { useState } from "react";
import { Form, Button, Container, Card } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";

const AddSupplier = () => {
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState({
    name: "",
    email: "",
    tel: "",
    hp: "",
    request_comment: "",
    city: "",
    address: "",
  });

  const handleChange = (e) => {
    setSupplier({ ...supplier, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    axios.post("api/addnewsuppliers", supplier).then((res) => {
      console.log(res.data);
      if (res.data.alreadyExist) {
        alert("ספק עם ח.פ. זהה כבר קיים במערכת");
      } else {
        navigate("/suppliers");
      }
    });

    console.log(supplier);
  };

  return (
    <Container className="d-flex justify-content-center align-items-center h-100">
      <Card style={{ width: "30rem" }}>
        <Card.Body>
          <h2 className="text-center mb-4">הוספת ספק</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formName">
              <Form.Label>שם</Form.Label>
              <Form.Control
                type="text"
                name="name"
                value={supplier.name}
                onChange={handleChange}
                placeholder="נא להזין את שם הספק"
                required
              />
            </Form.Group>

            <Form.Group controlId="formHp">
              <Form.Label>ח.פ.</Form.Label>
              <Form.Control
                type="number"
                name="hp"
                value={supplier.hp}
                onChange={handleChange}
                placeholder="נא להזין ח.פ. תקין"
                required
              />
            </Form.Group>

            <Form.Group controlId="formEmail">
              <Form.Label>מייל</Form.Label>
              <Form.Control
                type="email"
                name="email"
                value={supplier.email}
                onChange={handleChange}
                placeholder="נא להזין את כתובת המייל של הספק"
                required
              />
            </Form.Group>

            <Form.Group controlId="formPhone">
              <Form.Label>טלפון</Form.Label>
              <Form.Control
                type="tel"
                name="tel"
                value={supplier.phone}
                onChange={handleChange}
                placeholder="נא להזין את מספר הטלפון של הספק"
                required
              />
            </Form.Group>

            <Form.Group controlId="formCity">
              <Form.Label>עיר</Form.Label>
              <Form.Control
                type="text"
                name="city"
                value={supplier.city}
                onChange={handleChange}
                placeholder="נא להזין את שם העיר שבה ממוקמת החברה"
                required
              />
            </Form.Group>

            <Form.Group controlId="formAddress">
              <Form.Label>כתובת</Form.Label>
              <Form.Control
                type="text"
                name="address"
                value={supplier.address}
                onChange={handleChange}
                placeholder="נא להזין את כתובת הספק"
                required
              />
            </Form.Group>

            <Form.Group controlId="formRequestComment">
              <Form.Label>הערה</Form.Label>
              <Form.Control
                as="textarea"
                name="request_comment"
                value={supplier.request_comment}
                onChange={handleChange}
                placeholder="כאן ניתן להזין הערה אם צריך"
                required
              />
            </Form.Group>

            <div className="text-center mt-3">
              <Button variant="primary" type="submit">
                הוסף
              </Button>
              <Link to="/suppliers" className="btn btn-secondary me-2">
                חזור
              </Link>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddSupplier;
