import { useEffect, useState } from "react";
import { Container, Form, Row } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import axios from "axios";
const columns = [
     {
        name: 'שם משתמש',
        selector: row => row.name,
        sortable: true,
    }, {
        name: 'שם בית הספר',
        selector: row => row.school,
        sortable: true,
    }, {
        name: 'מייל',
        selector: row => row.email,
        sortable: true,
    }, {
        name: 'שכר',
        selector: row => row.teller,
        sortable: true,
    }, {
        name: 'כח אדם',
        selector: row => row.rep,
        sortable: true,
    },{
        name: 'ביטוחים',
        selector: row => row.insurance,
        sortable: true,
    },
];

function Users() {
    const [rows, setRows] = useState([]);
    const [display, setDisplay] = useState([]);

    useEffect(() => {
        axios.post("api/getUsers")
            .then((res) => {
                setRows(res.data.rows);
                setDisplay(res.data.rows);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <>
            <Container fluid className='text-center'>
                <h3 className='pt-2'>
                    מוסדות
                </h3>
                <hr />
                <DataTable
                    columns={columns}
                    data={display}
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="66vh"
                    subHeader
                    subHeaderComponent={
                        <Row>
                            <Form.Control type="text" placeholder="חיפוש" onChange={(e) => { setDisplay(rows.filter(i => `${i.type} ${i.name}${i.school}${i.email}${i.teller}${i.rep}${i.insurance}`.includes(e.target.value))) }} />
                        </Row>
                    }
                />
            </Container>
        </>
    );
}
export default Users;