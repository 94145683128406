import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import AddForm from './components/AddForm';
import NotFound from './components/NotFound';
import AddFiles from './components/AddFiles';
import Principal from './components/Principal';
import Checker from './components/Checker';
import SchoolF from './components/SchoolF';
import Dep from './components/Dep.js';
import Admin from './components/Admin';
import Top from './components/Top'

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Travel from './components/travel.jsx';
import TransportationSupplierManagement from './components/suplliers.jsx';
import TransportationDashboard from './components/dashboard.jsx';
import TransportationApprovals from './components/approval.jsx';
import InvoiceUploader from './components/payments.jsx';
import Invoice from './components/invoice.jsx';
import AddSupplier from './components/addsupplier.jsx';
import EditContract from './components/editcontract.jsx';

const router = createBrowserRouter([
  {
    path: "/",
    element: <>
      <Top show={0} />
      <App />
    </>,
  }, {
    path: "/contestent",
    element: <>
      <Top show={1} />
      <AddForm />
    </>,
  }, {
    path: "/confiles",
    element: <>
      <Top show={1} />
      <AddFiles />
    </>,
  }, {
    path: "/principal",
    element: <>
      <Top show={1} />
      <Principal />
    </>,
  }, {
    path: "/checker",
    element: <>
      <Top show={2} />
      <Checker />
    </>,
  }, {
    path: "/departments",
    element: <>
      <Top show={2} />
      <Dep />
    </>,
  }, {
    path: "/schoolfiles",
    element: <>
      <Top show={1} />
      <SchoolF />
    </>,
  }, {
    path: "/travel",
    element: <>
      <Top show={1} />
      <Travel />
    </>,
  }, {
    path: "/suppliers",
    element: <>
      <Top show={1} />
      <TransportationSupplierManagement />
    </>,
  }, {
    path: "/dashboard",
    element: <>
      <Top show={1} />
      <TransportationDashboard />
    </>,
  }, {
    path: "/approval",
    element: <>
      <Top show={1} />
      <TransportationApprovals />
    </>,
  }, {
    path: "/payments",
    element: <>
      <Top show={1} />
      <InvoiceUploader />
    </>,
  }, {
    path: "/invoice",
    element: <>
      <Top show={1} />
      <Invoice />
    </>,
  }, {
    path: "/addsupplier",
    element: <>
      <Top show={1} />
      <AddSupplier />
    </>,
  }, {
    path: "/editcontract",
    element: <>
      <Top show={1} />
      <EditContract />
    </>,
  }, {
    path: "/admin",
    element: <>
      <Top show={2} />
      <Admin />
    </>,
  }, {
    path: "*",
    element: <>
      <Top show={0} />
      <NotFound />
    </>,
  }

]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div style={{ backgroundColor: '#F9F9F9', minHeight: '100vh' }} dir="rtl">
      <RouterProvider router={router} />
    </div>
  </React.StrictMode>
);
