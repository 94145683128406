import React from 'react';
import { Link } from "react-router-dom";
import walle from './img/walle.png'
const NotFound = () => {
    return (
        <>
            <div className="text-center pt-5" >
                <img src={walle} alt='Not found' />
            </div>
            <div className="text-center" >
                <Link to="/" className='link'>כניסה</Link>
            </div>
        </>
    )
}
export default NotFound; 