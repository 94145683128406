import axios from "axios";
import React, { useEffect, useState } from "react";
import { Container, Form, Button, Table } from "react-bootstrap";
import { dateDisplayNoTime } from "../helpers/functions";
import { Link, useNavigate } from "react-router-dom";

const TransportationSupplierManagement = () => {
  const navigate = useNavigate();
  const [suppliers, setSuppliers] = useState([]);
  const [newSupplier, setNewSupplier] = useState({});

  useEffect(() => {
    axios.post("api/suppliers").then((res) => {
      console.log(res.data.rows);
      setSuppliers(res.data.rows);
    });
  }, []);

  const handleAddSupplier = () => {
    axios.post("api/suppliers/add", newSupplier).then((res) => {
      console.log(res.data);
      if (res.data.doesntExist) {
        navigate("/addsupplier");
      }
      setSuppliers(res.data.rows);
      setNewSupplier({});
    });
  };

  return (
    <Container>
      <h2 className="mt-4">ניהול ספקי הסעות</h2>
      <h3>הוספת ספק</h3>
      <Form>
        <Form.Group controlId="formName">
          <Form.Label>ח.פ. / עוסק מורשה</Form.Label>
          <Form.Control
            type="text"
            value={newSupplier.hp}
            onChange={(e) =>
              setNewSupplier({ ...newSupplier, hp: e.target.value })
            }
          />
        </Form.Group>
        {/* Add more form fields for other supplier details */}
        <Button variant="primary" className="mt-2" onClick={handleAddSupplier}>
          הוסף
        </Button>
      </Form>

      <h3 className="mt-4">רשימת ספקים</h3>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>שם</th>
            <th>ח.פ. / עוסק מורשה</th>
            <th>סטטוס</th>
            <th>תאריך התחלה</th>
            <th>סכום ליום</th>
            <th>חשבונות ששולמו</th>
            <th>סכום חשבונות</th>
            <th>תאריך סיום</th>
            <th>עריכה</th>
          </tr>
        </thead>
        <tbody>
          {suppliers.map((supplier) => (
            <tr key={supplier.id}>
              <td>{supplier.name}</td>
              <td>{supplier.hp}</td>
              <td>{supplier.status}</td>
              <td>{dateDisplayNoTime(supplier.cont_start_date)}</td>
              <td>{supplier.day_price}</td>
              <td>{supplier.invoicesPaid}</td>
              <td>{supplier.total}</td>
              <td>{dateDisplayNoTime(supplier.cont_end_date)}</td>
              <td>
                <Link to={`/editcontract?id=${supplier.conid}`}>
                  <Button variant="primary" className="me-1">
                    ערוך
                  </Button>
                </Link>
                <Link to={`/payments?id=${supplier.conid}`}>
                  <Button variant="primary" className="me-1">
                    הוספה
                  </Button>
                </Link>
                <Link to={`/invoice?id=${supplier.conid}`}>
                  <Button variant="primary" className="me-1">
                    חשבוניות
                  </Button>
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default TransportationSupplierManagement;
