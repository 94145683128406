import React, { useRef, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'
import './App.css';
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/esm/Row';
import Col from 'react-bootstrap/esm/Col';
import { Modal } from 'react-bootstrap';

const App = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [validated, setValidated] = useState(false);
  const [user, setUser] = useState('');
  const pass = useRef('');
  const [err, setErr] = useState('שם משתמש או סיסמה שגויים');

  const handleNewPass = (event) => {
    event.preventDefault();
    event.stopPropagation();
    axios.post("api/newpass", { user: user, email: event.target.email.value })
      .then((res) => {
        console.log(res.data);
        if (res.data.msg === 'problem') {
          alert('סמל מוסד או המייל שהוזן אינו תואם את הנתונים ששמורים במערכת, נא לפנות למנהל המערכת')
        }
      }).catch((error) => {
        console.error('Error:', error);
      });
    setShow(false)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      axios.post("api/login", { user: user, pass: pass.current.value })
        .then((res) => {
          if (res.data.msg === 'problem') {
            pass.current.value = '';
            setErr('סיסמא או שם משתמש שגויים')
          }
          if (res.data.type === 1) {
            sessionStorage.setItem('usertype', res.data.type);
            sessionStorage.setItem('name', res.data.name);
            sessionStorage.setItem('semel', res.data.semel);
            sessionStorage.setItem('city', res.data.city);
            sessionStorage.setItem('mosName', res.data.mosName);
            sessionStorage.setItem('email', res.data.email);
            navigate('/principal')
          } else
            if (res.data.type === 2) {
              sessionStorage.setItem('usertype', res.data.type);
              sessionStorage.setItem('name', res.data.name);
              navigate('/checker')
            } else
              if (res.data.type === 3) {
                sessionStorage.setItem('usertype', res.data.type);
                sessionStorage.setItem('name', res.data.name);
                sessionStorage.setItem('semel', res.data.semel);
                sessionStorage.setItem('email', res.data.email);
                navigate('/departments')
              } else
                if (res.data.type === 4) {
                  sessionStorage.setItem('usertype', res.data.type);
                  sessionStorage.setItem('name', res.data.name);
                  sessionStorage.setItem('semel', res.data.semel);
                  sessionStorage.setItem('email', res.data.email);
                  navigate('/admin')
                }
        }).catch((error) => {
          console.error('Error:', error);
        });
    }
    setValidated(true);
  }

  return (
    <div className="container pt-5">
      <Form noValidate validated={validated} onSubmit={handleSubmit}>
        <Row className='justify-content-md-center'>
          <Col xs={12} md={8} lg={6} xl={6} xxl={6}>
            <Card className="shadow p-3">
              <Card.Body>
                <Form.Group className="mb-3" controlId="user">
                  <Form.Label>שם משתמש</Form.Label>
                  <Form.Control type="text" placeholder="" required value={user} onChange={(e) => setUser(e.target.value)} />
                  <Form.Control.Feedback type="invalid">
                    נא למלא שם משתמש
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group className="mb-3" controlId="pass">
                  <Form.Label>סיסמא</Form.Label>
                  <div className='input-icon'>
                    <Form.Control type="password" required ref={pass} />
                    <span className="icon"
                      onMouseLeave={() => pass.current.type = "password"}
                      onMouseEnter={() => pass.current.type = "text"}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-eye" viewBox="0 0 16 16">
                        <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z" />
                        <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z" />
                      </svg>

                    </span>
                    <div className='float-start' onClick={() => setShow(true)}><u className='text-muted' role="button">שכחתי סיסמא</u></div>
                    <Form.Control.Feedback type="invalid">
                      {err}
                    </Form.Control.Feedback>
                  </div>
                </Form.Group>
              </Card.Body>
              <div className="mx-auto mb-3">
                <Button variant="primary" type="submit">כניסה</Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Form>

      <Modal show={show} onHide={() => setShow(false)} dir="rtl">
        <Modal.Header>
          <Modal.Title>איפוס סיסמה - <span className='text-muted'>ניתן לאפס רק אם שמור מייל במערכת</span></Modal.Title>
        </Modal.Header>
        <Form validated="true" onSubmit={handleNewPass}>
          <Modal.Body>
            <Form.Group className="mb-3" controlId="user">
              <Form.Label>שם משתמש</Form.Label>
              <Form.Control type="text" placeholder="" required value={user} onChange={(e) => setUser(e.target.value)} />
              <Form.Control.Feedback type="invalid">
                נא למלא שם משתמש
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3" controlId="user">
              <Form.Label>מייל ששמור במערכת</Form.Label>
              <Form.Control type="email" placeholder="1234@m-m.org" required name="email" />
              <Form.Control.Feedback type="invalid">
                נא למלא מייל
              </Form.Control.Feedback>
            </Form.Group>
            <p>לאחר איפוס הסיסמה המערכת תשלח אליכם מייל עם הסיסמה החדשה</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShow(false)}>
              נזכרתי
            </Button>
            <Button variant="primary" type='submit'>
              איפוס סיסמה
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default App;
