import React from "react";
import { Button } from "react-bootstrap";
import { utils, writeFile, writeFileSync } from "xlsx";

const TableToExcel = ({ data, fileName }) => {
    const exportToExcel = () => {
        const worksheet = utils.json_to_sheet(data);
        const workbook = utils.book_new();
        utils.book_append_sheet(workbook, worksheet, "Sheet1");
    
        // Choose between asynchronous or synchronous write
        writeFile(workbook, `${fileName}.xls`);
        // OR
        //writeFileSync(workbook, `${fileName}.xlsx`);
      };

  return <Button variant="success" className="float-start" onClick={exportToExcel}>אקסל</Button>;
};

export default TableToExcel;
