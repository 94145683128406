import React from "react";
import axios from "axios";
import { Form, Button, Container, Card, Col } from "react-bootstrap";
import { useNavigate, Link, useSearchParams } from "react-router-dom";

const InvoiceUploader = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  if (!id || isNaN(id) || id === "") {
    console.error("Invalid supplier ID:", id);
    navigate("/suppliers");
  }
  console.log("Supplier ID:", id);
  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    formData.append("contract_id", id);
    const invoiceFile = e.target.querySelector('[name="invoiceFile"]').files[0];
    let ext = invoiceFile.name.split(".").pop();
    formData.append("ext", ext);
    axios
      .post("api/payments", formData)
      .then((res) => {
        // Handle successful response
        console.log("Upload successful:", res);
        // Redirect to "/suppliers" or perform other actions as needed
        navigate("/suppliers");
      })
      .catch((error) => {
        // Handle error
        console.error("Error uploading:", error);
      });
  };

  return (
    <Container
      fluid
      className="d-flex justify-content-center align-items-center"
    >
      <Col xs={12} md={6}>
        <Card className="m-3 p-4">
          <h1 className="text-center mb-2">העלאת חשבונית</h1>
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formDocumentType">
              <Form.Label>סוג מסמך</Form.Label>
              <Form.Control as="select" name="documentType">
                <option value="invoice">חשבונית</option>
                <option value="recipt">קבלה</option>
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="formInvoiceNumber">
              <Form.Label>מספר חשבונית</Form.Label>
              <Form.Control type="text" name="invoiceNumber" required />
            </Form.Group>
            <Form.Group controlId="formFromDate">
              <Form.Label>מתאריך</Form.Label>
              <Form.Control type="date" name="fromDate" required />
            </Form.Group>
            <Form.Group controlId="formToDate">
              <Form.Label>עד תאריך</Form.Label>
              <Form.Control type="date" name="toDate" required />
            </Form.Group>
            <Form.Group controlId="formBillDays">
              <Form.Label>ימי חיוב</Form.Label>
              <Form.Control type="number" name="billDays" required />
            </Form.Group>
            <Form.Group controlId="total">
              <Form.Label>סכום</Form.Label>
              <Form.Control type="number" name="total" required />
            </Form.Group>
            <Form.Group controlId="formInvoiceFile">
              <Form.Label>העלאת חשבונית</Form.Label>
              <div className="custom-file">
                <Form.Control
                  type="file"
                  className="custom-file-input"
                  name="invoiceFile"
                  required
                />
              </div>
            </Form.Group>
            <div className="text-center mt-3">
              <Button variant="primary" type="submit">
                שלח
              </Button>
              <Link to="/suppliers" className="btn btn-secondary me-2">
                חזור
              </Link>
            </div>
          </Form>
        </Card>
      </Col>
    </Container>
  );
};

export default InvoiceUploader;
