import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Chart } from "react-google-charts";


function Status() {
    const [data, setData] = useState([
        ["Status", "Applications"],
        ["טרם הוגשו (בעריכה)", 0],
        ["הוגשו לרשת", 0],
        ["חזרו לבתי ספר מחכים לתיקונים", 0],
        ["טפסים תקינים", 0],
        ["שויכו בחישובית", 0],
    ]);

    useEffect(() => {
        axios.post("api/getStatus") 
            .then((res) => {
                setData([
                    ["Status", "Applications"],
                    ["טרם הוגשו (בעריכה)", res.data[0].count],
                    ["הוגשו לרשת", res.data[1].count],
                    ["חזרו לבתי ספר מחכים לתיקונים", res.data[2].count],
                    ["טפסים תקינים", res.data[3].count],
                    ["שויכו בחישובית", res.data[4].count],
                ]);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <Container className='text-center'>
            <h3 className='pt-2'>תמונת מצב בקשות</h3>
            <hr />
            <Chart
            className='overflow-hidden'
                chartType="PieChart"
                data={data}
                width={"100%"}
                height={"400px"}
            />
        </Container>
    );
}

export default Status;