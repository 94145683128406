import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";

const TransportationDashboard = () => {
  return (
    <Container>
      <h2 className="mt-4">דשבורד הסעות</h2>
      <Row className="mt-4">
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Requests Summary</Card.Title>
              <Card.Text>
                {/* Display summary of requests */}
                {/* For example: Total requests, pending requests, approved requests, etc. */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Financial Data</Card.Title>
              <Card.Text>
                {/* Display financial data */}
                {/* For example: Total budget, budget spent, remaining budget, etc. */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card>
            <Card.Body>
              <Card.Title>Process Tracking</Card.Title>
              <Card.Text>
                {/* Display process tracking information */}
                {/* For example: Status of budget allocation process, status of budget approval process, etc. */}
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default TransportationDashboard;
