import { useEffect, useRef, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { createOption, getFormattedDate } from "../../helpers/functions"
import axios from "axios";
import TableToExcel from "../../helpers/Excel";

function Edit() {
    const search = useRef(null);
    const [rows, setRows] = useState([]);
    const [display, setDisplay] = useState([]);
    const [insurance, setInsurance] = useState([]);
    const [manpower, setManpower] = useState([]);
    const [payment, setPayment] = useState([]);
    const [show, setShow] = useState(false);

    useEffect(() => {
        axios.post("api/getUsers")
            .then((res) => {
                setRows(res.data.rows);
                setDisplay(res.data.rows);
            }).catch((error) => {
                console.error('Error:', error);
            });
        axios.post("api/getMachlakot")
            .then((res) => {
                setInsurance(res.data.insurance);
                setPayment(res.data.payment);
                setManpower(res.data.manpower);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const handleChange = (type, val, user) => {
        const data = { type, val, user };
        console.log(data)
        axios.post("api/updaterep", data)
            .then((res) => {
                setRows(res.data.rows);
                setDisplay(res.data.rows.filter(i => `${i.name} ${i.school}${i.teller}${i.rep}${i.insurance}${i.payment ? "" : "שכר ריק"}${i.manpower ? "" : "כח אדם ריק"}${i.insuranceid ? "" : "ביטוחים ריק"}`.includes(search.current.value)));
            }).catch((error) => {
                console.error('Error:', error);
            });
    };
    const handleMailChange = (val, user) => {
        console.log(val, user);
        const data = { val, user };
        console.log(data)
        axios.post("api/updatemail", data)
            .then((res) => {
                setRows(res.data.rows);
                setDisplay(res.data.rows.filter(i => `${i.name} ${i.school}${i.teller}${i.rep}${i.insurance}${i.payment ? "" : "שכר ריק"}${i.manpower ? "" : "כח אדם ריק"}${i.insuranceid ? "" : "ביטוחים ריק"}`.includes(search.current.value)));
            }).catch((error) => {
                console.error('Error:', error);
            });
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData(event.target);
        console.log(Object.fromEntries(data.entries()))
        setShow(false)
        axios.post("api/addnewusermosad", Object.fromEntries(data.entries()))
            .then((res) => {
                if (res.data.msg === "duplicate") {
                    alert("סמל כבר קיים במערכת. לא בוצעו שינויים.")
                } else {
                    setRows(res.data.rows);
                    setDisplay(res.data.rows);
                }
            }).catch((error) => {
                console.error('Error:', error);
            });
    };


    // Function to extract specific columns from the data
    const extractSpecificColumns = (data) => {
        return data.map((row) => {
            const { name, school, email, teller, rep, insurance } = row;
            return {
                "סמל / שם משתמש": name,
                "שם בית הספר": school,
                "מייל": email,
                "שכר": teller,
                "כח אדם": rep,
                "ביטוח": insurance,
            };
        });
    };

    let specificColumnsData = extractSpecificColumns(display);

    return (
        <>
            <Container fluid className='text-center'>
                <h3 className='pt-2'>
                    ניהול משתמשים
                </h3>
                <hr />
                <Row className='mb-1'>
                    <Col xs={0} md={0} lg={3} xl={6} xxl={6} className='p-0'></Col>
                    <Col xs={12} md={4} lg={3} xl={2} xxl={2} >
                        <TableToExcel data={specificColumnsData} fileName={`data_${getFormattedDate()}`} />
                    </Col>
                    <Col xs={12} md={4} lg={3} xl={2} xxl={2} >
                        <Button variant="outline-primary" className="w-100" onClick={() => setShow(true)}>הוסף משתמש</Button>
                    </Col>
                    <Col xs={12} md={4} lg={3} xl={2} xxl={2} >
                        <Form.Control ref={search} type="text" placeholder="חיפוש" onChange={(e) => setDisplay(rows.filter(i => `${i.name} ${i.school}${i.teller}${i.rep}${i.insurance}${i.payment ? "" : "שכר ריק"}${i.manpower ? "" : "כח אדם ריק"}${i.insuranceid ? "" : "ביטוחים ריק"}`.includes(e.target.value)))} />
                    </Col>
                </Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>שם משתמש</th>
                            <th>בית ספר</th>
                            <th>מייל</th>
                            <th>שכר</th>
                            <th>כח אדם</th>
                            <th>ביטוחים</th>
                        </tr>
                    </thead>
                    <tbody>
                        {display.map((row, index) => {
                            return (
                                <tr key={index}>
                                    <td>{row.name}</td>
                                    <td>{row.school}</td>
                                    <td>
                                        <Form.Control value={row.email} onChange={(e) => handleMailChange(e.target.value, row.name)} />
                                    </td>
                                    <td><Form.Select value={row.payment} onChange={(e) => handleChange(3, e.target.value, row.name)}>
                                        <option hidden value="">{row.payment}</option>
                                        {createOption(payment)}
                                    </Form.Select></td>
                                    <td><Form.Select value={row.manpower} onChange={(e) => handleChange(2, e.target.value, row.name)}>
                                        <option hidden value=""></option>
                                        {createOption(manpower)}
                                    </Form.Select></td>
                                    <td><Form.Select value={row.insuranceid} onChange={(e) => handleChange(1, e.target.value, row.name)}>
                                        <option hidden value=""></option>
                                        {createOption(insurance)}
                                    </Form.Select></td>
                                </tr>
                            )
                        }
                        )}
                    </tbody>
                </Table>

                <Modal show={show} onHide={() => setShow(false)} dir="rtl">
                    <Modal.Header >
                        <Modal.Title>הוספת משתמש</Modal.Title>
                    </Modal.Header>
                    <Form onSubmit={handleSubmit}>
                        <Modal.Body>
                            <Row>
                                <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Group className="mb-3" controlId="semel">
                                        <Form.Label>סמל</Form.Label>
                                        <Form.Control required name="semel" type="text" placeholder="שם משתמש" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Group className="mb-3" controlId="pass">
                                        <Form.Label>סיסמא</Form.Label>
                                        <Form.Control required name="password" type="password" placeholder="סיסמא" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} lg={6} xl={6} xxl={6}>

                                    <Form.Group className="mb-3" controlId="sname">
                                        <Form.Label>שם בית הספר</Form.Label>
                                        <Form.Control required name="sname" type="text" placeholder="בית ספר" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Group className="mb-3" controlId="ty">
                                        <Form.Label>עיר בית ספר</Form.Label>
                                        <Form.Control required name="ty" type="text" placeholder="עיר" />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Group className="mb-3" controlId="mail">
                                        <Form.Label>מייל</Form.Label>
                                        <Form.Control required name="mail" type="email" placeholder="israel@israeli.com" />
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Group className="mb-3" controlId="pay">
                                        <Form.Label>שכר</Form.Label>
                                        <Form.Select required name="payment">
                                            {createOption(payment)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Group className="mb-3" controlId="man">
                                        <Form.Label>כח אדם</Form.Label>
                                        <Form.Select required name="manpower">
                                            {createOption(manpower)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                                <Col xs={12} md={6} lg={6} xl={6} xxl={6}>
                                    <Form.Group className="mb-3" controlId="ins">
                                        <Form.Label>ביטוחים</Form.Label>
                                        <Form.Select required name="insurance">
                                            {createOption(insurance)}
                                        </Form.Select>
                                    </Form.Group>
                                </Col>
                            </Row>

                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={() => setShow(false)}>
                                סגור ללא שמירה
                            </Button>
                            <Button variant="primary" type="submit">
                                שמור משתמש
                            </Button>
                        </Modal.Footer>
                    </Form>
                </Modal>
            </Container>
        </>
    );
}
export default Edit;