
import { Link } from 'react-router-dom';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';

const Status = ({ people, fun }) => {
    let { tz, fname, lname, status, id, formtype, cell, status2date, status3date, status4date, nsigned } = people
    let date;

    let stat = 'בעריכה – טרם הגשת בקשה';
    switch (status) {
        case 1:
            stat = "בעריכה – טרם הגשת בקשה";
            date = 0;
            break;
        case 2:
            stat = "בקשה הוגשה וממתינה לאישור הרשת";
            date = new Date(status2date)
            break;
        case 3:
            stat = "בקשה לא אושרה וממתינה להשלמת פרטים";
            date = new Date(status3date)
            break;
        case 4:
            stat = "אושרה קבלה מלאה של הבקשה והיא בטיפול הרשת";
            date = new Date(status4date)
            break;
        case 5:
            stat = "מצב ארכיון לאחר סגירת הבקשה על ידי הרשת ";
            break;
        default:
            break;
    }
    let display;
    if (date === 0) {
        display = 'טרם הוגש'
    } else {
        display = date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear() + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2);
    }

    return (
        <>
            <tr>
                <td>{display}</td>
                <td>{fname + ' ' + lname}</td>
                <td>{tz}</td>
                <td>{cell}</td>
                <td>{formtype === 0 ? 'עובד הוראה' : 'עובד עזר'}</td>
                <td className=''>
                    {status === 1 || status === 3 ? (
                        <>
                            {status === 3 && nsigned === '' ? null : (
                                <Link to={`/contestent?id=${id}`}>
                                    <Button variant="primary" className="ms-1" title="השלמת נתונים">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-person-lines-fill" viewBox="0 0 16 16">
                                            <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                                        </svg>
                                    </Button>
                                </Link>)}
                            <Link to={`/confiles?id=${id}`}>
                                <Button variant="success" className="ms-1" title='העלאת קבצים'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-file-earmark-arrow-up-fill" viewBox="0 0 16 16">
                                        <path d="M9.293 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.707A1 1 0 0 0 13.707 4L10 .293A1 1 0 0 0 9.293 0zM9.5 3.5v-2l3 3h-2a1 1 0 0 1-1-1zM6.354 9.854a.5.5 0 0 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 8.707V12.5a.5.5 0 0 1-1 0V8.707L6.354 9.854z" />
                                    </svg>
                                </Button>
                            </Link>
                            <OverlayTrigger
                                placement="top"
                                overlay={
                                    <Tooltip id={`tooltip-top`}>
                                    מחיקת בקשה בלחיצה כפולה
                                    </Tooltip>
                                }
                            >
                                <Button variant="danger" className="ms-1" title='מחיקת בקשה'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" className="bi bi-file-earmark-arrow-up-fill" viewBox="0 0 16 16" onDoubleClick={() => fun(id)}>
                                        <path d="M11 1.5v1h3.5a.5.5 0 0 1 0 1h-.538l-.853 10.66A2 2 0 0 1 11.115 16h-6.23a2 2 0 0 1-1.994-1.84L2.038 3.5H1.5a.5.5 0 0 1 0-1H5v-1A1.5 1.5 0 0 1 6.5 0h3A1.5 1.5 0 0 1 11 1.5Zm-5 0v1h4v-1a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5ZM4.5 5.029l.5 8.5a.5.5 0 1 0 .998-.06l-.5-8.5a.5.5 0 1 0-.998.06Zm6.53-.528a.5.5 0 0 0-.528.47l-.5 8.5a.5.5 0 0 0 .998.058l.5-8.5a.5.5 0 0 0-.47-.528ZM8 4.5a.5.5 0 0 0-.5.5v8.5a.5.5 0 0 0 1 0V5a.5.5 0 0 0-.5-.5Z" />
                                    </svg>
                                </Button>
                            </OverlayTrigger>

                        </>
                    ) : null}
                </td>
                <td className='text-center'><OverlayTrigger
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-top`}>
                            {stat}
                        </Tooltip>
                    }
                >
                    <Button variant="secondary" className="">{status}</Button>
                </OverlayTrigger></td>
            </tr>
        </>
    )
}
export default Status