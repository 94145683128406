import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useSearchParams } from "react-router-dom";
import { Button, Modal, Table } from "react-bootstrap";
import UFiles from "./UFiles";
import { dateDisplayNoTime } from "../helpers/functions";

const Invoice = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get("id");
  const [invoices, setInvoices] = useState([]);
  const [URL, setUrl] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (invoice) => {
    console.log(invoice);
    axios
      .post("api/getuploads3", {
        bucket: "tranportation",
        url: invoice.invoice_file,
      })
      .then((res) => {
        setUrl(res.data);
      });
    setShow(true);
  };

  useEffect(() => {
    axios.post("api/invoices", { id }).then((res) => {
      console.log(res.data.rows);
      setInvoices(res.data.rows);
    });
  }, []);

  return (
    <div className="container">
      <span className="float-start">
        {" "}
        <Link to="/suppliers">חזרה לספקים</Link>
      </span>
      <h1>חשבוניות</h1>

      <Table striped bordered hover>
        <thead>
          <tr>
            <th>#</th>
            <th>סוג</th>
            <th>מס'</th>
            <th>מתאריך</th>
            <th>עד</th>
            <th>ימי חיוב</th>
            <th>סכום</th>
            <th>קובץ</th>
          </tr>
        </thead>
        <tbody>
          {invoices.map((invoice, index) => (
            <tr key={invoice.supid}>
              <td>{index + 1}</td>
              <td>
                {invoice.document_type === "receipt" ? "קבלה" : "חשבונית"}
              </td>
              <td>{invoice.invoice_number}</td>
              <td>{dateDisplayNoTime(invoice.from_date)}</td>
              <td>{dateDisplayNoTime(invoice.to_date)}</td>
              <td>{invoice.bill_days}</td>
              <td>{invoice.amount}</td>
              <td>
                <Button
                  variant="primary"
                  className="btn-sm"
                  onClick={() => handleShow(invoice)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="16"
                    fill="currentColor"
                    className="bi bi-file-earmark-text"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                    <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                  </svg>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {/* Modal for displaying file */}
      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <UFiles key={URL} url={URL} />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Invoice;
