import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import icon from './img/icon.png'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import api from '../apis/axiosset';

const Top = ({ show }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [name, setName] = useState('');
    useEffect(() => {
        setName(sessionStorage.getItem('name'))
        const type = sessionStorage.getItem('usertype')
        if (type !== '3' && location.pathname === '/departments') {
            navigate('/')
        } else if (type !== '2' && location.pathname === '/checker') {
            navigate('/')
        } else if (type !== '4' && location.pathname === '/admin') {
            navigate('/')
        } else if (sessionStorage.getItem('name') === null && location.pathname !== '/') {
            navigate('/')
        }
    }, [location]);
    const logout = () => {
        api.post("api/logout")
        sessionStorage.removeItem('name')
        navigate('/')
    }
    return (
        <>
            <Navbar bg="dark" variant="dark" className="d-print-none">
                <Container>
                    <Navbar.Brand className="m-0 p-0"><img src={icon} alt="logo" width="50px" /> מערכת מעל"ה</Navbar.Brand>
                    <Nav className="me-auto d-print-none">
                        {show === 1 ? (

                            <Nav.Link>
                                <Link to="/principal" className=''>
                                    דשבורד
                                </Link>
                            </Nav.Link>

                        ) : (<></>)}
                        {show > 0 ? (
                            <Nav.Link onClick={logout}>יציאה</Nav.Link>
                        ) : (<></>)}
                        <Nav.Link>{name}</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </>
    )
}
export default Top