import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";
import DataTable from 'react-data-table-component';
import axios from "axios";
const columns = [
    {
        name: 'עיר',
        selector: row => row.ty,
        sortable: true,
    }, {
        name: 'שם מוסד',
        selector: row => row.MosName,
        sortable: true,
    }, {
        name: 'סוג',
        selector: row => row.Mtype,
        sortable: true,
    }, {
        name: 'מין',
        selector: row => row.Gender,
        sortable: true,
    }, {
        name: 'סמל',
        selector: row => row.Semel,
        sortable: true,
    }, {
        name: 'מנהל',
        selector: row => row.Principal,
        sortable: true,
    }, {
        name: 'טל מנהל',
        selector: row => row.Ptel,
        sortable: true,
    }, {
        name: 'מייל מנהל',
        selector: row => row.Pmail,
        sortable: true,
    },
];


export default function Mosdot() {
    const [rows, setRows] = useState([]);
    const [display, setDisplay] = useState([]);

    useEffect(() => {
        axios.post("api/getMosdot")
            .then((res) => {
                setRows(res.data.rows);
                setDisplay(res.data.rows);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    return (
        <>
            <Container fluid className='text-center'>
                <h3 className='pt-2'>
                    מוסדות
                </h3>
                <hr />
                <DataTable
                    columns={columns}
                    data={display}
                    striped
                    pagination
                    fixedHeader
                    fixedHeaderScrollHeight="66vh"
                    subHeader
                    subHeaderComponent={
                        <Row>
                            <Form.Control type="text" placeholder="חיפוש" onChange={(e) => { setDisplay(rows.filter(i => `${i.type} ${i.name}${i.school}${i.email}${i.teller}${i.rep}`.includes(e.target.value))) }} />
                        </Row>
                    }
                />
            </Container>
        </>
    );
}