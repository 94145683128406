import { Button, Col, Container, Form, Row, Table } from "react-bootstrap";

import { dateDisplay } from '../../helpers/functions';
import { useEffect, useRef, useState } from "react";
import axios from "axios";

function Options() {
    const [topics, setTopics] = useState([]);
    const add = useRef(null);

    useEffect(() => {
        axios.post("api/getTopics")
            .then((res) => {
                setTopics(res.data.rows);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }, []);

    const handleSwitch = (e, id) => {
        axios.post("api/switchTopic", { id, active: e.target.checked ? 1 : 0 })
            .then((res) => {
                setTopics(res.data.rows);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }

    const addCatogory = () => {
        console.log(add.current.value);

        axios.post("api/addTopic", { topic: add.current.value })
            .then((res) => {
                setTopics(res.data.rows);
            }).catch((error) => {
                console.error('Error:', error);
            });
    }

    return (
        <>
            <Container className='text-center'>
                <h3 className='pt-2'>נושאים להעלאת קבצים לבתי הספר</h3>
                <hr />
                <Form.Group className="mb-3">
                    <Row className='justify-content-center'>
                        <Col xs={0} md={0} lg={0} xl={0} xxl={1} className='text-start'></Col>
                        <Col xs={12} md={7} lg={6} xl={5} xxl={4} className='text-start'>
                            <Form.Label>להוספת קטגוריה להעלאת קבצים <br/>(שם הקטגוריה צריך להיות ייחודי, מומלץ להכניס שנה) </Form.Label>
                        </Col>
                        <Col xs={12} md={3} lg={2} xl={3} xxl={3} className=''>
                            <Form.Control type="text" ref={add}/>
                        </Col>
                        <Col xs={12} md={2} lg={2} xl={2} xxl={2} className='text-end'>
                            <Button variant="outline-primary" onClick={addCatogory}>הוסף</Button>
                        </Col>
                    </Row>
                </Form.Group>
                <hr />
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>שם הקטגוריה</th>
                            <th>תאריך הוספה</th>
                            <th>כמות קבצים שהועלו</th>
                            <th>פעיל</th>
                        </tr>
                    </thead>
                    <tbody>
                        {topics.map((topic) => {
                            return (
                                <tr key={topic.idtopics}>
                                    <td>{topic.idtopics}</td>
                                    <td>{topic.topic}</td>
                                    <td>{dateDisplay(topic.date)}</td>
                                    <td>{topic.count}</td>
                                    <td>
                                        <Form.Check
                                            type="switch"
                                            id={topic.idtopics}
                                            checked={topic.active === 1 ? true : false}
                                            onChange={(e) => {handleSwitch(e, topic.idtopics)}}
                                        />
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Container>
        </>
    );
}
export default Options;